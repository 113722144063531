import React from 'react';
import styled from 'styled-components';
import Table from 'react-bootstrap/Table';

function JsonTable({ data }) {


   // Get all keys from the first object in the array
   const keys = data? Object.keys(data[0] || {}) : [];
  
   return (
     <Table striped bordered hover>
       <thead className='cust-thead'>
        <tr>
           {keys.map(key => (
             <th key={key}>{key}</th>
           ))}
         </tr>
       </thead>
       <tbody>
         {data.map((item, index) => (
           <tr key={index}>
             {keys.map(key => (
               <td key={key}>{item[key]}</td>
             ))}
           </tr>
         ))}
       </tbody>
     </Table>
   );
}


export default JsonTable;

const Wrapper = styled.div`
table tr td {
  white-space: nowrap;
}
font-size:14px;
.cust-thead{
    position: sticky !important;
    top: 0;
    background-color: var(--clr-primary-5); /* Background color for the fixed header */
}
`