import axios from "axios";

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
};

const _axios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});


const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  getAxiosClient,
};

export default HttpService;
