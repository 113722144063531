import './App.css';
import styled from 'styled-components';
import { ReactNotifications } from 'react-notifications-component'

import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { Navbar , SimpleFooter} from './components'
import Home from './pages/Home';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>

      <Route element={<AppLayout />}  >
        <Route path="/"
          element={<Home />}>
        </Route>
      </Route>
    </>
  )
);

function AppLayout() {



  return (
    <>
      <ReactNotifications />

      <Wrapper className="root">
        <Navbar />
        <Home />
        <SimpleFooter />
      </Wrapper>

    </>

  );
}

function App() {
  return (

    <RouterProvider router={router} />

  )
}

const Wrapper = styled.div`  
    display : flex;
    flex-direction: column;
    width:100%;
    min-height:100vh;
    padding:0px;
    margin:0px;
    > div:nth-child(2){
      height:fit-content;
    }  
`

export default App;
