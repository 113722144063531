import React from 'react'
import styled from 'styled-components'
import QuationLogo from '../../assets/logo.png';

function Navbar() {
  return (
    <NavContainer>
     
        <nav>
          <div className="grid-item item1">
            <img src={QuationLogo} height='100' alt="Quation Logo" />
          </div>
          <div className="grid-item item2">
            <span>PDF Extraction</span>
          </div>
          <div className="grid-item item3"></div>
        </nav>
     
    </NavContainer>
    
  )
}

export default Navbar;

const NavContainer = styled.div`  
 
header {
    height: fit-content;
    width: 100vw;
    z-index: 10;
    position:absolute;
    top:0;
    left:0;
    }

  nav {
      display: grid;
      grid-template-columns: auto 1fr auto;
      -webkit-user-select: none;
      user-select: none;
      background-color: transparent;
      margin: 0 auto;
      position: static;
      background: rgb(255,255,255);
      background:var(--clr-primary-3);
  }

  .item2{
    font-size:44px;    
    font-weight:500;
    text-align:center;
    margin-right:18rem;
    padding-top:1rem;
    
  }

  h1{
    text-align:center;
    margin: 0 auto;
  }

`