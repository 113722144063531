import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import '../assets/css/button66.css';
import { ApiEnums, postRequest, getRequest } from '../services/DataRequestService';
import { JsonTable } from '../components';
import { exportToExcel } from '../utils/helper';
import LoaderImage from '../assets/loader.gif';

function Home() {

  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [showUploadPDFContainer, setShowUploadPDFContainer] = useState(true);
  const inputFile = useRef(null);
  const [fileName, setFileName] = useState(null);


  function toExcel(formData) {
    let requestObject = {
      url: ApiEnums.toExcel,
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      },
      data: formData
    }
    const responseObject = postRequest(requestObject);
    return responseObject;
  }

  const handleFileChange = (event) => {
    let fileName  = event.target.files[0].name;
    if(fileName){
       fileName = fileName.split('.')[0];
       setFileName(fileName);      
    }
    
    setSelectedFile(event.target.files[0]);
  };

  const onClickSubmitHandler = async () => {
    console.log('submitted');
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', selectedFile);
      const responseData = await toExcel(formData);
      setJsonData(responseData.data);
      setLoading(false);
      setShowUploadPDFContainer(false);
    } catch (error) {

    }
  }

  const onClickDownloadHandler = () => {
    console.log('clicked on Download');
    let _fileName = fileName ? fileName : 'Untitled';
    exportToExcel(jsonData, _fileName, 'Sheet1');
  }

  const onClickResetHandler = () => {
    setJsonData([]);
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
    }
    setShowUploadPDFContainer(true);
  }

  return (
    <Wrapper>
      <div className="page-container">
        {showUploadPDFContainer ?
          <div id="box1" className='control-box' >
            <div className="controls-section">
              <span className='control-header'>Upload PDF File</span>
            </div>
            <div className="controls-section">
              <input type="file" id="myFile" ref={inputFile} onChange={handleFileChange} name="filename" />
              <button className="button-66" onClick={onClickSubmitHandler} role="button">Submit</button>
            </div>
            {loading && <div className="controls-section">
              <div className='loader-section'>
                <img src={LoaderImage} width="50px" height="50px" />
                <br />
                <span> We are processing... please wait. </span>
              </div>
            </div>}
          </div>
          :
          <div id="box2" className='control-box' >
            <div className="controls-section">
              <span className='control-header'>Download extracted data from PDF into excel sheet</span>
            </div>
            <div className="controls-section">
              <button className="button-66" onClick={onClickDownloadHandler} role="button">Download</button>
              <button className="button-66 button-gray" onClick={onClickResetHandler} role="button">Reset</button>
            </div>
          </div>
        }


        <br />
        <div className="table-section">

          <div className="table-container">
            {Array.isArray(jsonData) && <JsonTable data={jsonData} />}
          </div>
        </div>
      </div>


    </Wrapper>
  )
}

export default Home;


const Wrapper = styled.div`
 .page-container{
   width : 80vw;
   height:100%;
   margin:0 auto;
   padding:5rem;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
 }

 .control-box{
  width:80%;
  margin:0 auto;
  padding:2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
 }

 .control-header {
  font-weight:bold;
  font-size:24px;
  color: var(--clr-second-1);
 }

 .controls-section{
   display:flex;
   flex-direction:row;
   row-gap:10px;
   justify-content:space-around;
   width:80%;
   margin:0 auto;
   /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
 }

 .loader-section{
    text-align:center;
 }
 input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  margin-top:12px;
}

.table-container{
  width:100%;
  height:250px;
  overflow:auto;
}

.cust-thead{
    position: sticky;
    top: 0;
    background-color: #f8f9fa; /* Background color for the fixed header */
}

.button-gray{
  background-color : gray !important;
}

table {
    /* font-family: Quicksand, Arial, sans-serif; */
    border-collapse: collapse;
    width: 100%;
    text-wrap: nowrap;
    overflow-y: scroll;
    overflow-x:auto;
    font-size: 14px;
    height:fit-content;

  }
  
  table td, table th {
    border: 1px solid var(--clr-primary-8);
    padding: 8px;
  }
  
  table tr:nth-child(even){background-color: var(--clr-white);} 
  
  table tr:hover {background-color: var(--clr-primary-8);}
  
  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--clr-primary-5);
    color: white;
  }

`